$(function () {
    function removeSymbols(inputString) {
        return inputString.replace(/[?&,]/g, '');
    }

    document.querySelectorAll('[data-toc] a').forEach(anchor => {
        let hash = anchor.hash;
        let updatedHash = removeSymbols(hash);
        anchor.setAttribute('href', updatedHash);
    });

    document.querySelectorAll('.toc h1, .toc h2, .toc h3, .toc h4, .toc h5, .toc h6').forEach(header => {
        let id = header.id;
        let updatedId = removeSymbols(id);
        header.setAttribute('id', updatedId);
    });
});