$(function () {
    let $listItems = $('.js-wys-nav-list > li'),
        $currentMenuItem = $listItems.find('.sub-menu > li.current-menu-item');

    $listItems.find('.sub-menu').slideUp(500);

    if ($currentMenuItem.length > 0) {
        let $activeParent = $currentMenuItem.closest('li.current-menu-parent');
        $activeParent.addClass('active-menu');
        $activeParent.find('.sub-menu').slideDown(500);
    } else {
        if ($listItems.length === 1) {
            $listItems.addClass('single-menu-item');
            $listItems.toggleClass('active-menu');
            $listItems.first().find('.sub-menu').slideDown(500);
        } else {
            $listItems.first().addClass('active-menu');
            $listItems.first().find('.sub-menu').slideDown(500);
        }
    }

    $listItems.click(function() {
        if ($listItems.length !== 1) {
            $listItems.not($(this)).removeClass('active-menu').find('.sub-menu').slideUp(500);
            $(this).toggleClass('active-menu');
            $(this).find('.sub-menu').slideToggle(500);
        } else {
            $(this).find('.sub-menu').slideToggle(500);
            $(this).toggleClass('active-menu');
        }
    });
});