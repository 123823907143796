document.addEventListener("DOMContentLoaded", () => {
    const $body = document.querySelector('body'),
        $menuBtn = document.querySelector('.js-menu-btn'),
        $mobileMenu = document.querySelector('.js-mobile-menu'),
        $mobileOverlay = document.querySelector('.js-mob-overlay'),
        $header = document.querySelector('.page-header');

    //Sticky-header
    function stickyHeader() {
        if($(window).scrollTop() > 0) {
            $header.classList.add('sticky');
        } else {
            $header.classList.remove('sticky');
        }
    }

    $menuBtn.addEventListener('click', () => {
        $body.classList.toggle('ov-hidden');
        $menuBtn.classList.toggle('open');
        $mobileMenu.classList.toggle('open');
        $mobileOverlay.classList.toggle('open');
        setTimeout(() => {
            $header.classList.toggle('active');
        }, 150);
    });

    //Sub menu
    if (window.matchMedia("(max-width: 992px)").matches) {
        $('.mega-menu-item-has-children > .mega-sub-menu').addClass('main-sub-menu');
        $('.main-sub-menu').slideUp();

        setTimeout(function () {
            $('.mega-menu-item-has-children > .mega-menu-link').on('click', function H(e) {
                e.preventDefault();
                $(this).siblings('.main-sub-menu').slideToggle();
                $(this).toggleClass('active');
            });
        }, 0);
    }

    document.addEventListener('scroll', () => {
        stickyHeader();
    });
});