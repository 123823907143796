// import Swiper JS
import Swiper, {Navigation, Pagination, EffectFade, EffectCreative} from 'swiper';
Swiper.use([ Navigation, Pagination, EffectFade, EffectCreative ]);

function setCarouselMarginLeft(carouselBlock) {
    function updateMargin() {
        let $footer = document.querySelector('.page-footer');
        let $container = $footer.querySelector('.page-container');
        let mL = parseInt(window.getComputedStyle($container).marginLeft);
        let pL = parseInt(window.getComputedStyle($container).paddingLeft);
        let carouselElements = document.querySelectorAll(`.${carouselBlock}`);
        carouselElements.forEach(function(element) {
            element.style.marginLeft = (mL + pL) + 'px';
        });
    }

    window.addEventListener('load', updateMargin);
    window.addEventListener('resize', updateMargin);
}

function formatNumberWithLeadingZeros(num) {
    return num.toString().padStart(2, '0');
}

const $testimonialsSwiper = new Swiper('.testimonialsSwiper', {
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 15,
    navigation: {
        prevEl: '.js-testimonials-btn-prev',
        nextEl: '.js-testimonials-btn-next',
    },
    breakpoints: {
        993: {
            spaceBetween: 20
        }
    }
});

const $testimonialsFullSwiper = new Swiper('.testimonialsFullSwiper', {
    loop: true,
    allowTouchMove: false,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 900,
    slidesPerView: 1,
    navigation: {
        prevEl: '.js-testimonials-full-btn-prev',
        nextEl: '.js-testimonials-full-btn-next',
    }
});

const $addictionsSwiper = new Swiper('.addictionsSwiper', {
    loop: true,
    grabCursor: true,
    slidesPerView: 'auto',
    pagination: {
        el: '.js-addictions-pagination',
        type: 'progressbar',
    },
    navigation: {
        prevEl: '.js-addictions-btn-prev',
        nextEl: '.js-addictions-btn-next',
    }
});

setCarouselMarginLeft('addictionsSwiper');

const $infoSwiperfraction = document.querySelector('.js-info-slider-pagination-2'),
    $infoMobSwiperfraction = document.querySelector('.js-info-mob-slider-pagination-2');

function updateInfoSliderCounter(swiper, el) {
    let currentIndex = swiper.realIndex + 1;
    let slideCount = swiper.slides.length;
    let formattedIndex = formatNumberWithLeadingZeros(currentIndex);
    let formattedSlideCount = formatNumberWithLeadingZeros(slideCount);

    el.textContent = `${formattedIndex} / ${formattedSlideCount}`;
}

const $infoRightSwiper = new Swiper('.infoRightSwiper', {
    allowTouchMove: false,
    effect: 'creative',
    slidesPerView: 1,
    speed: 900,
    creativeEffect: {
        prev: {
            translate: [0, 0, -15],
            scale: 1.015
        },
        next: {
            translate: ['100%', 0, 0],
        },
    },
    pagination: {
        el: '.js-info-slider-pagination',
        type: 'progressbar',
    },
    navigation: {
        prevEl: '.js-info-slider-btn-prev',
        nextEl: '.js-info-slider-btn-next',
    }
});

const $infoLeftSwiper = new Swiper('.infoLeftSwiper', {
    allowTouchMove: false,
    effect: 'creative',
    slidesPerView: 1,
    speed: 900,
    creativeEffect: {
        prev: {
            translate: [0, 0, -15],
            scale: 1.015
        },
        next: {
            translate: ['100%', 0, 0],
        },
    },
    pagination: {
        el: '.js-info-slider-pagination',
        type: 'progressbar',
    },
    navigation: {
        prevEl: '.js-info-slider-btn-prev',
        nextEl: '.js-info-slider-btn-next',
    }
});

const $infoSwiper = new Swiper('.infoSwiper', {
    allowTouchMove: false,
    effect: 'fade',
    slidesPerView: 1,
    speed: 900,
    fadeEffect: {
        crossFade: true
    },
    pagination: {
        el: '.js-info-slider-pagination',
        type: 'progressbar',
    },
    navigation: {
        prevEl: '.js-info-slider-btn-prev',
        nextEl: '.js-info-slider-btn-next',
    },
    on: {
        init: (swiper) => {
            updateInfoSliderCounter(swiper, $infoSwiperfraction);
        },
        slideChange: (swiper) => {
            updateInfoSliderCounter(swiper, $infoSwiperfraction);
        }
    }
});

const $infoMobSwiper = new Swiper('.infoMobSwiper', {
    allowTouchMove: false,
    effect: 'fade',
    slidesPerView: 1,
    speed: 900,
    fadeEffect: {
        crossFade: true
    },
    pagination: {
        el: '.js-info-mob-slider-pagination',
        type: 'progressbar',
    },
    navigation: {
        prevEl: '.js-info-slider-btn-prev',
        nextEl: '.js-info-slider-btn-next',
    },
    on: {
        init: (swiper) => {
            updateInfoSliderCounter(swiper, $infoMobSwiperfraction);
        },
        slideChange: (swiper) => {
            updateInfoSliderCounter(swiper, $infoMobSwiperfraction);
        }
    }
});

const $ourAccreditationsSwiper = new Swiper('.ourAccreditationsSwiper', {
    loop: true,
    grabCursor: true,
    slidesPerView: 2,
    spaceBetween: 30,
    navigation: {
        prevEl: '.js-our-accreditations-btn-prev',
        nextEl: '.js-our-accreditations-btn-next',
    },
    breakpoints: {
        641: {
            slidesPerView: 3,
            spaceBetween: 60
        },
        991: {
            slidesPerView: 4,
            spaceBetween: 100
        }
    }
});

setCarouselMarginLeft('ourAccreditationsSwiper');

const $exploreSwiper = new Swiper('.exploreSwiper', {
    loop: true,
    grabCursor: true,
    slidesPerView: 'auto',
    spaceBetween: 15,
    navigation: {
        prevEl: '.js-explore-carousel-btn-prev',
        nextEl: '.js-explore-carousel-btn-next',
    },
    breakpoints: {
        768: {
            spaceBetween: 25
        }
    }
});

setCarouselMarginLeft('exploreSwiper');

const $simpleSliderSwiper = new Swiper('.js-simple-slider-swiper', {
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 15,
    navigation: {
        prevEl: '.js-simple-slider-btn-prev',
        nextEl: '.js-simple-slider-btn-next',
    }
});

const $ppcLandingContentSwiper = new Swiper('.ppcLandingContentSwiper', {
    loop: true,
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 15,
    navigation: {
        prevEl: '.js-ppc-landing-content-gallery-btn-prev',
        nextEl: '.js-ppc-landing-content-gallery-btn-next',
    },
    breakpoints: {
        641: {
            slidesPerView: 2
        }
    }
});