import AOS from 'aos';

$(function () {
    $('a[href^="#"]').click(function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - document.querySelector('.page-header').offsetHeight
        }, 800);
        return false;
    });

    $('.page-footer__menu-label').click(function() {

        if (window.matchMedia("(max-width: 767px)").matches) {
            $(this).toggleClass('active-item');
            $(this).next().toggle(500);
        }
    });
});

window.addEventListener('load', () => {
    setTimeout(() => {
        AOS.init({
            once: true,
            duration: 700,
            delay: 300
        });
    }, 100);
});