$(function () {
    function initAccordion(trigger, activeItemFirst, activeItemClass, hideItemFirst) {
        trigger.on('click', function H() {
            let self = $(this);
            trigger.not(self).siblings()
                .slideUp(400);
            self.parent().siblings().removeClass(`${activeItemClass}`);
            if (self.parent().hasClass(`${activeItemClass}`)) {
                self.siblings().slideUp(400);
                self.parent().removeClass(`${activeItemClass}`);
            } else {
                $(this).siblings()
                    .slideDown(400);
                $(this).parent().addClass(`${activeItemClass}`);
            }
        });

        if(!hideItemFirst) {
            if (activeItemFirst) {
                activeItemFirst.first().find(trigger)
                    .siblings()
                    .slideDown(400);
                activeItemFirst.first().addClass(`${activeItemClass}`);
            }
        }
    }

    initAccordion($('.js-trigger-accordion'), $('.js-accordion-item'), 'active', false);
    initAccordion($('.js-sources-trigger-accordion'), $('.js-sources-accordion-item'), 'active', true);
    initAccordion($('.js-positions-trigger-accordion'), $('.js-positions-accordion-item'), 'active', true);
    initAccordion($('.js-content-w-trigger-accordion'), $('.js-content-w-accordion-item'), 'active', true);
    initAccordion($('.js-jl-trigger-accordion'), $('.js-jl-accordion-item'), 'active', true);
});