document.addEventListener("DOMContentLoaded", () => {
    const $lists = document.querySelectorAll('.js-anim-line');

    function animateLine($list, translateX) {
        let totalWidth = 0;
        const $items = $list.querySelectorAll('.js-anim-el');
        $items.forEach(function(childElement) {
            totalWidth += childElement.offsetWidth;
        });

        $list.style.transform = `translate3d(${-translateX}px, 0, 0)`;
        $list.style.willChange = 'transform';

        translateX += 1;

        if (translateX <= totalWidth / 2) {
            requestAnimationFrame(() => animateLine($list, translateX));
        } else {
            $list.style.transform = 'translate3d(0, 0, 0)';
            translateX = 0;
            requestAnimationFrame(() => animateLine($list, translateX));
        }
    }

    if ($lists.length > 0) {
        $lists.forEach($list => {
            animateLine($list, 0);
        });
    }
});