// import styles
import '@scss/main.scss';
import '@lib/scss/base.lib.scss';
//impost scripts
import 'jquery.toc/jquery.toc.js';
// require main js file
require('@js/index');
require('@js/wys-nav');
require('@js/accordions');
require('@js/video-bg');
require('@js/header');
require('@js/animations');
require('@js/sliders');
require('@js/ajax-blog');
require('@js/select');
require('@js/toc');
