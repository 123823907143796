(function($) {

    $(document).ready(function() {
        let pageNumber = 1, myajax = `//${window.location.host}/wp-admin/admin-ajax.php`,
            paramObj = {
                url: myajax,
                action: 'blog_ajax',
                category: '',
                sort: '',
                searchKeyword: '',
                paged: 1,
            };

        function filters(paramObj) {
            $.ajax({
                url: paramObj.url,
                data: paramObj,
                type: 'post',
                beforeSend() {
                    $('.js-posts-ajax').css({ 'opacity': '0.3', 'pointer-events': 'none' });
                },
                complete() {
                    $('.js-posts-ajax').css({ 'opacity': '1', 'pointer-events': 'auto' });
                },
                success(result) {
                    $('html, body').animate({ scrollTop: $('.posts').offset().top - 140 }, 500);
                    $('.js-posts-ajax').html(result);
                },
                error(result) {
                    dl.log(result);
                }
            });
        }

        $(document).on('click', '.c-pagination .page-numbers', function H(e) {
            let catId = $(this).parent().data('cat-id');

            e.preventDefault();
            if($(this).hasClass('next')) {
                paramObj.paged = Number($('.c-pagination .page-numbers.current').html())+1;
            } else if($(this).hasClass('prev')) {
                paramObj.paged = Number($('.c-pagination .page-numbers.current').html())-1;
            } else {
                paramObj.paged = $(this).html();
            }

            if (catId) {
                paramObj.category = catId;
            }

            filters(paramObj);
        });

        $('.js-posts-category').on('change', function H(e) {
            e.preventDefault();
            paramObj.category = $('.js-posts-category').val();
            paramObj.paged = 1;
            filters(paramObj);
        });

        $('.js-posts-sort').on('change', function H(e) {
            e.preventDefault();
            paramObj.sort = $('.js-posts-sort').val();
            paramObj.paged = 1;
            filters(paramObj);
        });
    });
})(jQuery);
