import SlimSelect from 'slim-select';

const selectElements = document.querySelectorAll('select.slim-select');
if (selectElements.length > 0) {
    selectElements.forEach(function (selectElement) {
        new SlimSelect({
            select: selectElement,
            settings: {
                showSearch: false
            }
        });
    });
}